import { proxy } from "valtio";
import { HeaderTheme } from "./Header";
import { regionDetect } from "@utils/regionDetect";
import { User } from "firebase/auth";
import { ToolbarType } from "./Toolbar";
import { LeaderboardDataType } from "@blocks/leaderboard";
import { LessonType } from "@blocks/home/course";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "@utils/firebaseConfig";

const DATA_URL = "https://storage.googleapis.com/nz-herpes-foundation.appspot.com/leaderboard.json"

const LESSONS: LessonType[] = [
  {
    number: 1,
    title: `What is herpes?`,
    description: "Herpes 101, taught by someone who really hates viruses—Sir Ashley Bloomfield.",
    length: "2:19",
    thumb: env.themePath + "/assets/images/lessons/thumbs/episode-1.jpg",
    clip: env.themePath + "/assets/videos/lessons/episode-1.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1015080109?h=d79596fdcb"
  },
  {
    number: 2,
    title: `How common is herpes?`,
    description: "Turns out, you’re probably already hosting it. Anne Batley-Burton explains.",
    length: "2:08",
    thumb: env.themePath + "/assets/images/lessons/thumbs/episode-2.jpg",
    clip: env.themePath + "/assets/videos/lessons/episode-2.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1015083307?h=2fa0699fc8"
  },
  {
    number: 3,
    title: `Is herpes scary?`,
    description: "Not according to Sir Buck Shelford. (Spoiler alert: it’s not.)",
    length: "1:25",
    thumb: env.themePath + "/assets/images/lessons/thumbs/episode-3.jpg",
    clip: env.themePath + "/assets/videos/lessons/episode-3.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1015086624?h=d93688abec"
  },
  {
    number: 4,
    title: `How to not make herpes awkward`,
    description: "Angella Dravid shows you how to drop herpes into conversation.",
    length: "1:50",
    thumb: env.themePath + "/assets/images/lessons/thumbs/episode-4.jpg",
    clip: env.themePath + "/assets/videos/lessons/episode-4.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1015087776?h=802d4c5a7c"
  },
  {
    number: 5,
    title: `How to fight stigma`,
    description: "Mea Motu demonstrates the proper technique for busting herpes myths.",
    length: "1:20",
    thumb: env.themePath + "/assets/images/lessons/thumbs/episode-5.jpg",
    clip: env.themePath + "/assets/videos/lessons/episode-5.mp4",
    vimeoUrl: "https://player.vimeo.com/video/1015088823?h=c3ca977adc"
  },
]

export const SiteState = proxy({
  isMenuOpen: false,
  headerTheme: "light" as HeaderTheme,
  toolbarState: "live-index" as ToolbarType,
  region: regionDetect(),
  user: null as User | null,
  lessons: LESSONS,
  lessonsWatched: [] as number[],
  currentLesson: 0,
  leaderboardData: {} as LeaderboardDataType,
  statsCountries: 0,
  statsKiwis: 0,
  statsOthers: 0,
  statsViews: 0,
  statsShares: 0,
  getStats: async () => {
    const statsDoc = await getDoc(doc(db, "stats", "site"))
    if (statsDoc.exists()) {
      const data = statsDoc.data()
      SiteState.statsCountries = data.countries
      SiteState.statsKiwis = data.kiwis
      SiteState.statsOthers = data.others
      SiteState.statsViews = data.views
      SiteState.statsShares = data.shares
    }
  },
  hasUserShared: false,
  getHasUserShared: async () => {
    if (!SiteState.user) return
    try {
      const sdoc = await getDoc(doc(db, "shares", SiteState.user.uid))
      SiteState.hasUserShared = sdoc.exists()
    } catch (error) {
      // console.error(error)
    }
  },
  setUserShared: async () => {
    if (!SiteState.user) return
    try {
      await setDoc(doc(db, "shares", SiteState.user.uid), {
        shares: 1,
        country: SiteState.region,
        createdAt: serverTimestamp()
      })
      SiteState.hasUserShared = true
    } catch (error) {
      // console.error(error)
    }
  },
  fetchLeaderboardData: async () => {
    try {
      const response = await fetch(`${DATA_URL}?t=${Date.now()}`)
      const data = await response.json()
      SiteState.leaderboardData = data
    } catch (error) {
      console.error(error)
    }
  },
  scrollToId: (id: string) => {
    let lastHeight = 0, tries = 0
    const timer = setInterval(() => {
      const el = document.getElementById(id)
      if (el) {
        let height = document.body.offsetHeight
        if (height !== lastHeight) {
          // console.log('nope, height changing')
          lastHeight = height
          tries = 0
          return
        } else if (tries < 3) {
          // console.log('height not changing, but under try limit', tries)
          tries++
          return
        }
        clearInterval(timer)
        // console.log('scroll into view')
        el.scrollIntoView({ behavior: "smooth" })
      }
    }, 100)
  },
  scrollToCourse: () => {
    SiteState.scrollToId("course")
  },
  scrollToLeaderboard: () => {
    SiteState.scrollToId("leaderboard")
  },
})