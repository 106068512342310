// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA6CYf-szExZNRuMlqLwiJ2IwOFgv77viE",
  authDomain: "nz-herpes-foundation.firebaseapp.com",
  projectId: "nz-herpes-foundation",
  storageBucket: "nz-herpes-foundation.appspot.com",
  messagingSenderId: "425838462409",
  appId: "1:425838462409:web:c124c26cae3c38828dd418"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { app, db }